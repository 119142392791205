define("ember-intl/-private/formatters/format-relative", ["exports", "@ember/debug", "ember-intl/-private/formatters/-base"], function (_exports, _debug, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
                                                                                                                                                                                                                                                                                                                                                                                             * Copyright 2015, Yahoo! Inc.
                                                                                                                                                                                                                                                                                                                                                                                             * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
                                                                                                                                                                                                                                                                                                                                                                                             */
  /**
   * An object with some or all of properties of `options` parameter
   * of `Intl.RelativeTimeFormat` constructor.
   *
   * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
   *
   * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
   */

  /**
   * @private
   * @hide
   */
  class FormatRelative extends _base.default {
    format(intl, value, formatOptions) {
      (true && !(formatOptions) && (0, _debug.assert)(`[ember-intl] FormatRelative: Missing option`, formatOptions));
      const {
        format
      } = formatOptions;
      let unit = formatOptions.unit;
      let opts = formatOptions;
      if (!unit && format && intl.formats.relative && (opts = intl.formats.relative[format])) {
        unit = opts.unit;
      }
      (true && !(unit) && (0, _debug.assert)(`[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'. ${JSON.stringify(formatOptions)}`, unit));
      return intl.formatRelativeTime(value, unit, opts);
    }
  }
  _exports.default = FormatRelative;
  _defineProperty(FormatRelative, "type", 'relative');
});